import zhHKLocal from 'element-ui/lib/locale/lang/zh-TW'
import ac_zhHK from "./activity/ac_zhHk";
const zhHK = {
    400000: '學院',
    400001: '新聞',
    400002: '數據',
    400003: '視頻',
    400004: '學習',
    400005: '登錄',
    400006: '註冊',
    400007: '錢包',
    400008: '錢包總覽',
    400009: '現貨主板區賬戶',

    400010: 'P2P賬戶',
    400011: '合約賬戶',
    400012: '錢包歷史記錄',
    400013: '現貨主板區訂單',
    400014: '合約訂單',
    400015: '總覽',
    400016: '身份認證',
    400017: '賬戶安全',
    400018: '返傭',
    400019: '偏好設置',

    400020: '卡券中心',
    400021: '退出登錄',
    400022: '<em class="normal">{num}</em> 條新通知',
    400023: '清除全部',
    400024: '查看更多',
    400025: '暫無數據',
    400026: '掃描下載APP',
    400027: '更多下載選擇',
    400028: '通知詳情',
    400029: '知道了',

    400030: 'P2P',
    400031: '行情',
    400032: '現貨',
    400033: '合約',
    400034: 'Super Start',
    400035: '該地址不存在',
    400036: '全球最具信賴的數字資產交易平台',
    400037: '關於',
    400038: '服務協議',
    400039: '隱私條款',

    400040: '公告中心',
    400041: '學習',
    400042: '新手中心',
    400043: '現貨交易',
    400044: '合約交易',
    400045: '常見問題',
    400046: '服務',
    400047: '費率標準',
    400048: '交易規則',
    400049: '上幣申請',

    400050: '返傭',
    400051: '聯繫我們',
    400052: '幫助中心',
    400053: '提交工單',
    400054: '商務聯絡',
    400055: '機構合作',
    400056: '語言和地區',
    400057: '匯率',
    400058: '選擇語言和地區',
    400059: '選擇匯率',

    400060: '劃轉',
    400061: 'SuperEx內部轉賬不收取手續費',
    400062: '從',
    400063: '到',
    400064: '交易對',
    400065: '手續費抵扣',
    400066: '全倉',
    400067: '逐倉',
    400068: '分倉',
    400069: '餘額',

    400070: '幣種',
    400071: '搜索',
    400072: '數量',
    400073: '可用資產',
    400074: '全部',
    400075: '確認劃轉',
    400076: '開通',
    400077: '下次再說',
    400078: '開通合約賬戶',
    400079: '您尚未開通合約賬戶,暫時無法進行劃轉',

    400080: '{timeout}秒後重發',
    400081: '發送驗證碼',
    400082: '所有幣種',
    400083: '所有法幣',
    400084: '所有狀態',
    400085: '自選區',
    400086: '第三方',
    400087: '幫助指引',
    400088: '訂單',
    400089: 'P2P商戶中心',

    400090: 'P2P用戶中心',
    400091: '收款方式',
    400092: '發布新廣告',
    400093: '我的廣告',
    400094: '更多',
    400095: '去申請',
    400096: '取消',
    400097: '您暫時無法發布廣告',
    400098: '請先申請成為廣告方',
    400099: '去認證',

    400100: '暫不認證',
    400101: '您尚未完成身份認證',
    400102: '為了您與您交易用戶的賬號安全,請先完成身份認證',
    400103: '設置暱稱',
    400104: '請設置您的賬號暱稱,建議不要使用真實姓名.暱稱長度2~10個字符.',
    400105: '抱歉，現在暫時無法申請成為商戶。 ',
    400106: '設置成功',
    400107: 'SuperEx P2P的優勢',
    400108: '0手續費',
    400109: '在SuperEx P2P，您可以方便快捷地交易比特幣等數字貨幣，下單方以零手續費交易。',

    400110: '靈活的支付方式',
    400111: 'SuperEx P2P讓賣家可以自由選擇支付方式。 ',
    400112: '以更優價格交易',
    400113: 'SuperEx P2P讓用戶可以自主定價，選擇自己偏好的價格買賣數字貨幣。 ',
    400114: '安全交易比特幣等數字貨幣',
    400115: 'SuperEx P2P首創T+N提現機制，對商家嚴格管理，風控大數據篩選排查可疑交易，確保平台資金安全，用戶能安全交易。 ',
    400116: '{amount} 成交量',
    400117: '{num} %成交率',
    400118: '價格',
    400119: '支付時效',

    400120: '賣家收款方式',
    400121: '交易條款',
    400122: '我要支付',
    400123: '我將收到',
    400124: '我要賣',
    400125: '支付方式',
    400126: '設置收款方式',
    400127: '添加收款方式',
    400128: '參考單價 {price}',
    400129: '購買',

    400130: '出售',
    400131: '為了保護資產安全，將對買入資產實行{limit}提幣限制',
    400132: '了解更多',
    400133: '我知道了',
    400134: '根據您證件國和平台運營規範，請您切換到本國貨幣進行交易',
    400135: '購買限額: {min}-{max} {fiat}',
    400136: '出售限額: {min}-{max} {fiat}',
    400137: '廣告主',
    400138: '限額/數量',
    400139: '交易',

    400140: '數量',
    400141: '限額',
    400142: '去處理',
    400143: '好的',
    400144: '開始交易前，請先完成身份認證',
    400145: '您的今日訂單取消次數已超{num}次，無法繼續交易，請在{time}之後再嘗試',
    400146: '您的待處理訂單已達上限（{num}個）。請先完成正在進行的訂單。 ',
    400147: '該廣告主待處理訂單已達上限，請稍後操作或嘗試其他廣告。 ',
    400148: '您未滿足廣告方要求，請嘗試其他廣告',
    400149: '請選擇',

    400150: 'P2P如何運作',
    400151: '購買數字貨幣',
    400152: '出售數字貨幣',
    400153: '下訂單',
    400154: '下訂單後，賣家該筆交易的資產將由 SuperEx P2P 託管。 ',
    400155: '向賣方付款',
    400156: '通過交易信息中的付款方式向賣家匯款。完成匯款後在 SuperEx P2P 上點擊“已付款，通知賣方”',
    400157: '獲取您的數字貨幣',
    400158: '一旦賣家確認收到款項，託管的貨幣將被發放給您。 ',
    400159: '確認付款',

    400160: '檢查您帳戶中的交易記錄，並確保您收到買家發送的款項。 ',
    400161: '放行數字貨幣',
    400162: '確認收到款項後，在 SuperEx P2P 上向買家發放數字貨幣。 ',
    400163: '金額',
    400164: '輸入金額',
    400165: '法幣',
    400166: '所有支付方式',
    400167: '廣告編號',
    400168: '確定',
    400169: '確定要下架廣告嗎？ ',

    400170: '1.下架廣告用戶將無法通過廣告與您進行交易',
    400171: '2.下架廣告不影響正在進行的訂單',
    400172: '確認刪除該廣告嗎？ ',
    400173: '編輯',
    400174: '刪除',
    400175: '已上架',
    400176: '已下架',
    400177: '創建時間',
    400178: '最低限額',
    400179: '剩餘量/委託量',

    400180: '委託價',
    400181: '類型',
    400182: '上架成功',
    400183: '所有類型',
    400184: '狀態',
    400185: '廣告編號/幣種/日期',
    400186: '操作',
    400187: '根據您SuperEx身份認證的國家或地區和平台的運營規範，請您選擇支持的法幣進行交易。',
    400188: '不滿足廣告方要求',
    400189: '删除成功',

    400190: '下架成功',
    400191: '發布廣告',
    400192: '編輯廣告',
    400193: '固定價格',
    400194: '浮動價格',
    400195: '價格浮動比例',
    400196: '請輸入',
    400197: '交易價格',
    400198: '市場參考價',
    400199: '設置浮動比例可以讓您以高於或低於市場實時參考價一定百分比進行買賣',

    400200: '固定價格不會隨市場波動發生變化',
    400201: '交易限制',
    400202: '請輸入交易數量',
    400203: '交易數量',
    400204: '最小單筆限額',
    400205: '請輸入最小單筆限額',
    400206: '最大單筆限額',
    400207: '請輸入最大單筆限額',
    400208: '最大',
    400209: '該廣告所需手續費約 {rate}% {currency},當前可用{available} {currency}',

    400210: '付款方式',
    400211: '最多選擇3個',
    400212: '暫無付款方式',
    400213: '請選擇支付時效',
    400214: '請輸入內容',
    400215: '交易用戶條件',
    400216: '增加交易用戶限制，會減少您廣告的展示機會',
    400217: '完成註冊',
    400218: '天后',
    400219: '立即上線',

    400220: '稍後上線',
    400221: '風險提示',
    400222: '必須輸入數字',
    400223: '請選擇付款方式',
    400224: '請輸入浮動比例',
    400225: '請輸入固定價格',
    400226: '價格已偏離市場 {num}%,繼續發布將存在風險，請確認是否繼續發布廣告？ ',
    400227: '最大單筆限額需大於最小單筆限額',
    400228: '最大單筆限額不得高於{num} {fiat}',
    400229: '最小單筆限額不得低於{num} {fiat}',

    400230: '最小單筆限額需小於最大單筆限額',
    400231: '最大交易數量不得高於{num} {currency}',
    400232: '發布成功',
    400233: 'P2P交易暫不對錢包授權用戶開放，請使用SuperEx賬號登錄後進行P2P交易',
    400234: 'DAO網絡',
    400235: 'Roadmap',
    400236: '邀請好友',
    400237: '幫助中心',
    400238: '空投基地',
    400239: '主網瀏覽器',
    400240: '測試網瀏覽器',
    400241: '開發者文檔',
    400242: '活動中心',
    400243: '使用 SuperEx 網站和/或維護您的帳戶，即表示您同意我們更新的<a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/zh-hk/articles/11461213305625">《使用條款》</a>、<a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/zh-hk/articles/4413122414105">《隱私政策》</a>和 <a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/zh-hk/articles/20755770711577">《Cookie 政策》</a>。通過點擊“X”，您確認已閱讀並同意上述條款和政策。',
    400244: '購買Tether |獲得USDT | SuperEx P2P',
    400245: '任務中心',
    400246: '貨幣',
    400247: '收到',
    400248: '支出',
    400249: '可用餘額',

    400250: '支付',
    400251: '訂單詳情信息',
    400252: '金額（含交易費用）',
    400253: '到達時間',
    400254: '約{time}分鐘',
    400255: '提示',
    400256: '1.您将离开SUPEREX并进入第三方AlchemyPay网页以完成您的订单。',
    400257: '2. 订单完成后，您可以前往您的 <span class="pointer">财务记录</span> 查看详细信息。',
    400258: '3. 如果您需要付款方面的帮助，请联系付款提供商的 <span class="pointer">客户支持</span>。',
    400259: '4. 更多详情请参阅常见问题解答。 <span class="pointer">常问问题</span>',

    400260: '我已閱讀並同意免責聲明',
    400261: '如何在SuperEx上購買加密貨幣？',
    400262: '如何在SuperEx上出售加密貨幣？',
    400263: '下達加密貨幣訂單',
    400264: '選擇您想要支付的法定貨幣和您想要購買的加密貨幣。輸入您想要花費的金額。',
    400265: '驗證您的身份',
    400266: '在SuperEx和支付提供商上完成KYC驗證，完成支付。',
    400267: '檢查您的訂單詳細信息',
    400268: '檢查您的存款歷史記錄以查看訂單狀態。',
    400269: '1. 我可以使用哪些支付方式購買加密貨幣？',

    400270: 'SuperEx目前支持VISA、Mastercard、Apple Pay、Google Pay等多種支付方式。支持的第三方服務提供商包括 Mercuryo、alchemypay。',
    400271: '2. 我可以購買哪些加密貨幣？',
    400272: 'SuperEx支持BTC、ETH、USDT、LTC、USDC、XRP、BCH、CRV、TRX等主流加密貨幣。',
    400273: '3. SuperEx是否監控支持的第三方服務提供商的質量和價格？',
    400274: 'SuperEx精心挑選了一批具有值得信賴的行業信譽、知名度、安全性的服務提供商，為用戶提供流程化的服務。',
    400275: '4. 購買加密貨幣需要支付哪些費用？',
    400276: '您向第三方服務提供商支付費用和鏈上轉賬費用。 SuperEx不收取任何費用。',
    400277: '5. 付款後需要多長時間才能收到加密貨幣？',
    400278: '當您在第三方服務商平台完成支付後，您的加密貨幣將在大約2-10分鐘內存入您的SuperEx現貨賬戶。',
    400279: '6. 購買過程中遇到問題怎麼辦？',

    400280: '如果您在交易過程中遇到任何問題，請聯繫客戶支持。如果您付款完成後仍未收到加密貨幣，請聯繫第三方服務提供商檢查 訂單詳細信息（這通常是最有效的方法）。由於您當前所在地區的IP或某些政策原因，您必須選擇人工驗證。',
    400281: '單筆限額:',
    400282: '請輸入數量',
    400283: '付款渠道',
    400284: '獲得',
    400285: '餘額不足',
    400286:"您將離開SuperEx並跳轉到<span>{name}</span>，這是一家有執照的金融機構，作為第三方提供銀行卡支付能力。使用中發生任何風險事件均由<span>{name}</span>承擔。在使用<span>{name}</span>服務之前，請先閱讀並同意其<a class='pointer' target='_blank' href={server}>服務協議</a>如果您對信用卡押金有任何疑問，請聯繫 <a class='pointer' target='_blank' href={Customer}>{name}客服</a>以了解更多詳情。",
        // "您将离开SuperEx并跳转到<span>{name}</span>，这是一家有执照的金融机构，作为第三方提供银行卡支付能力。使用中发生任何风险事件均由<span>{name}</span>承担。在使用<span>{name}</span>服务之前，请先阅读并同意其 <a class='pointer' target='_blank' href={server}>服务协议</a> 如果您对信用卡押金有任何疑问，请联系 {name} 客服以了解更多详情。",
    400287: '免責聲明',
    400289: '可用現貨賬戶',
    400290: '確認',
    400291: 'ET 專區',


    400293: 'https://support.superex.com/hc/zh-hk/articles/23083845808537',
    400294: '快速買幣',

    400295:'請打開safari瀏覽器使用Applepay',
    400288: '金融',
    400292:'金融賬戶',//？

    410000:'SCS 空投',
    410001:'代理商',

    410002:'金融業務',
    100756: '理財',
    100219: '活動',
    101154: '認購歷史',

    122000:"全球DAO分布和社媒鏈接",
    122001:"邀請好友獲取獎勵",
    122002:"SuperEx的發展規劃",
    122003:"解決使用中遇見的常見問題",
    122004:"完成任務獲取獎勵",
    122005:"ET的介紹",
    122006:"SuperEx的介紹",
    122007:"代理商推薦返傭",
    122008:"低手續費購買加密貨幣",
    122009:"便捷購買加密貨幣",
    122010:"加密貨幣交易市場",
    122011:"自由區和主板區代幣交易",
    122012:"合約交易市場",
    122013:"高價值項目的IEO平台",
    122014:"存錢理財賺取收益",
    122015:"超低價購物",
    122016:"精彩活動聚集地",
    122017:"搶先佈局BTC生態新幣",
    122018:"SCS空投任務中心",
    122019:"SCS Chain官網",
    122020:"SCS Chain瀏覽器",
    122021:"SCS Chain測試網瀏覽器",
    122022:"開發人員文檔",
    122023:"瀏覽最新消息",
    122024:"快速瞭解最新事件概況",
    122025:"在YouTube上查看SuperEx",
    122026:"學習關於區塊鏈的一切",
    122027:"與朋友一起驚喜開盒",
    122028: '盲盒獎勵',

    //合约积分
    9100007: '交易積分',
    9100031: '交易積分活動中心',

    9100032: '量化基金',
    9100033: '專業財富管理',

    8100000: '用戶反饋',
    8000153: '自由上幣',
    8000154: 'BTC協議市場',
    6000072: '現貨自由區訂單',
    6000073: '現貨自由區賬戶',

    
    'target_1': '現貨賬戶',
    'target_2': 'P2P賬戶',
    'target_3': '币本位合約賬戶',
    'target_4': 'U本位合約賬戶',
    'target_5': '超级合約賬戶',
    'target_6': '金融-可用利息賬戶',

    ...zhHKLocal,
    ...ac_zhHK
}
export default zhHK;
