import krLocal from 'element-ui/lib/locale/lang/ko'
import ac_kr from "./activity/ac_kr";

const kr = {
    400000: '대학',
    400001: '소식',
    400002: '데이터',
    400003: '동영상',
    400004: '공부',
    400005: '로그인',
    400006: '회원가입',
    400007: '월렛',
    400008: '월렛 전체',
    400009: '현물 메인보드 지역 계정',

    400010: 'P2P 계정',
    400011: '선물 계좌',
    400012: '월렛 기록',
    400013: '현물 주문서',
    400014: '선물 주문',
    400015: '전체',
    400016: '본인 인증',
    400017: '계정 안전',
    400018: '수수료 리베이트',
    400019: '취향 설정',

    400020: '쿠폰센터',
    400021: '로그아웃',
    400022: '알람<em class="normal">{num}</em>개 들어왔음',
    400023: '모두 지우기',
    400024: '더 보기',
    400025: '데이터가 없음',
    400026: '스캔하여 앱 다운로드',
    400027: '추가 다운로드',
    400028: '알림 정보',
    400029: '알겠습니다',

    400030: 'P2P',
    400031: '시세',
    400032: '현물',
    400033: '선물',
    400034: 'Super Start',
    400035: '주소가 존재하지 않습니다',
    400036: '전세계에 제일 믿음직한 디저털 자산 거래소',
    400037: '소개',
    400038: '이용약관',
    400039: '개인정보처리방침',

    400040: '공지사항',
    400041: '学习',
    400042: '아카데미',
    400043: '현물 거래',
    400044: '선물거래',
    400045: '자주하는 질문',
    400046: '서비스',
    400047: '펀딩',
    400048: '거래 규칙',
    400049: '상장지원',

    400050: '수수료 리베이트',
    400051: '고객지원',
    400052: '고객센터',
    400053: '신청서 제출',
    400054: '비지니스 문의',
    400055: '미디어 콜라보',
    400056: '언어 및 지역',
    400057: '법정 화폐',
    400058: '언어 및 지역 선택',
    400059: '환율 선택',

    400060: '전송',
    400061: 'SuperEx에서 전송하면 수수료를 안 내도 됩니다.',
    400062: 'From',
    400063: 'To',
    400064: '페어',
    400065: '수수료 공제',
    400066: '교차',
    400067: '격리',
    400068: '분할 모드',
    400069: '잔액',

    400070: '통화',
    400071: '검색',
    400072: '수량',
    400073: '사용가능한 자산',
    400074: '전체',
    400075: '전송 확인',
    400076: '개통하기',
    400077: '나중에',
    400078: '선물 계좌 개통하기',
    400079: '선물 계좌를 개통한 후에 전송할 수 있습니다',

    400080: '{timeout}초 후 다시 보내기',
    400081: '인증번호 보내기',
    400082: '모든 통화',
    400083: '모든 화폐 통화',
    400084: '모든 주',
    400085: '자기 선택 영역',
    400086: '타사',
    400087: '도움말 가이드',
    400088: '주문하다',
    400089: 'P2P 판매자 센터',

    400090: 'P2P 사용자 센터',
    400091: '지불 방법',
    400092: '새 광고 게시',
    400093: '내 광고',
    400094: '더',
    400095: '신청하다',
    400096: '취소',
    400097: '일시적으로 광고를 게시할 수 없습니다',
    400098: '먼저 광고주가 되기 위해 신청하세요',
    400099: '인증하러 가기',

    400100: '아직 인증되지 않음',
    400101: '신원 확인을 완료하지 않았습니다.',
    400102: '거래 이용자의 계정 보안을 위해 먼저 본인 인증을 완료해주세요.',
    400103: '닉네임 설정',
    400104: '계정 닉네임을 설정해주세요.실명은 사용하지 않는 것을 권장합니다.닉네임의 길이는 2~10자입니다.',
    400105: '죄송합니다. 일시적으로 판매자 신청을 할 수 없습니다.',
    400106: '설정 성공',
    400107: 'SuperEx P2P의 장점',
    400108: '취급 수수료 0',
    400109: 'SuperEx P2P에서는 비트코인 ​​및 기타 디지털 통화를 편리하고 빠르게 거래할 수 있으며, 주문하는 당사자는 수수료 없이 거래할 수 있습니다.',

    400110: '유연한 지불 방법',
    400111: 'SuperEx P2P를 통해 판매자는 결제 방법을 자유롭게 선택할 수 있습니다.。',
    400112: '더 좋은 가격에 거래하세요',
    400113: 'SuperEx P2P를 통해 사용자는 자신의 가격을 설정하고 원하는 가격을 선택하여 디지털 통화를 사고 팔 수 있습니다.',
    400114: '비트코인과 같은 디지털 화폐를 안전하게 거래하세요',
    400115: 'SuperEx P2P는 T+n 현금 인출 메커니즘을 채택하고 가맹점을 엄격하게 관리하며 플랫폼 자산의 안전과 사용자가 안전하게 거래할 수 있도록 위험 관리 빅 데이터로 의심스러운 거래를 차단합니다.',
    400116: '{amount} 거래량',
    400117: '{num}% 거래율',
    400118: '가격',
    400119: '지불 기한',

    400120: '판매자 지불 방법',
    400121: '거래 조건',
    400122: '지불하다',
    400123: '나는 받을 것이다',
    400124: '나는 팔고 싶다',
    400125: '결제수단',
    400126: '결제 수단 설정',
    400127: '결제 수단 추가',
    400128: '참고 단가 {price}',
    400129: '구입',

    400130: '팔다',
    400131: '자금의 안전을 위해 구매한 자산에 대해 {limit} 출금 한도를 적용합니다.',
    400132: '더 알아보기',
    400133: '내가 참조',
    400134: '인증서의 국가 및 플랫폼의 운영 사양에 따라 거래를 위해 현지 통화로 전환하십시오.',
    400135: '구매 한도: {min}-{max} {fiat}',
    400136: '판매 할당량: {min}-{max} {fiat}',
    400137: '광고주',
    400138: '상한/수량',
    400139: '거래',

    400140: '수량',
    400141: '한도액',
    400142: '다루기 위해서',
    400143: '네',
    400144: '거래를 시작하기 전에 본인 확인을 완료하십시오.',
    400145: '오늘 주문 취소가 {num}회를 초과하여 거래를 계속할 수 없습니다. {time} 이후에 다시 시도하십시오.',
    400146: '보류 중인 주문이 상한선({num})에 도달했습니다. 진행 중인 주문을 먼저 완료하십시오.',
    400147: '광고주가 보류 중인 주문 한도에 도달했습니다. 나중에 작업하거나 다른 광고를 시도하십시오.',
    400148: '광고주의 요구사항을 충족하지 못했습니다. 다른 광고를 시도하십시오.',
    400149: '선택하세요',

    400150: 'P2P 작동 방식',
    400151: '디지털 통화 구매',
    400152: '디지털 통화 판매',
    400153: '주문을 하다',
    400154: '주문 후 이 거래에 대한 판매자의 자산은 SuperEx P2P에 의해 에스크로됩니다.',
    400155: '판매자에게 지불',
    400156: '거래내역에 있는 결제수단을 통해 판매자에게 송금하고, 송금 완료 후 SuperEx P2P에서 "결제 완료, 판매자에게 알림" 클릭',
    400157: '디지털 화폐 받기',
    400158: '판매자가 지불 수령을 확인하면 에스크로된 통화가 귀하에게 해제됩니다.',
    400159: '결제 확인',

    400160: '계정의 거래 내역을 확인하고 구매자로부터 대금을 받았는지 확인하세요.',
    400161: '디지털 통화 출시',
    400162: '결제 확인 후 SuperEx P2P에서 구매자에게 디지털 통화를 발행합니다',
    400163: '금액',
    400164: '금액을 입력하세요',
    400165: '법정 화폐',
    400166: '모든 결제 수단',
    400167: '광고 ID',
    400168: '확신하는',
    400169: '광고를 취소하시겠습니까?',

    400170: '1. 광고를 제거한 사용자는 광고를 통해 귀하와 거래할 수 없습니다.',
    400171: '2. 광고 제거는 진행중인 주문에 영향을 미치지 않습니다.',
    400172: '이 광고를 삭제하시겠습니까?',
    400173: '편집하다',
    400174: '삭제',
    400175: '활성화',
    400176: '취소 된',
    400177: '생성 시간',
    400178: '최소 금액',
    400179: '잔여금액/위탁금액',

    400180: '수수료 가격',
    400181: '유형',
    400182: '성공적으로 출시됨',
    400183: '모든 유형',
    400184: '상태',
    400185: '광고 ID/통화/날짜',
    400186: '운영하다',
    400187: '귀하의 SuperEx ID가 인증된 국가 또는 지역의 운영 사양 및 플랫폼에 따라 거래를 위해 지원되는 법적 통화를 선택하십시오.',
    400188: '광고주 요구사항을 충족하지 않음',
    400189: '성공적으로 삭제',

    400190: '성공적으로 제거됨',
    400191: '풀어 주다',
    400192: '광고 수정',
    400193: '고정 가격',
    400194: '부동 가격',
    400195: '가격 변동 비율',
    400196: '입력하십시오',
    400197: '거래 가격',
    400198: '시장 참조 가격',
    400199: '유동 비율을 설정하면 실시간 시장 기준 가격보다 높거나 낮은 특정 비율로 거래할 수 있습니다.',

    400200: '고정 가격은 시장 변동에 따라 변경되지 않습니다.',
    400201: '거래 한도',
    400202: '거래수량을 입력해주세요',
    400203: '거래 수량',
    400204: '최소 단일 한도',
    400205: '최소 단일 거래 한도를 입력하세요',
    400206: '최대 단일 한도',
    400207: '최대 단일 거래 한도를 입력하세요',
    400208: '상한',
    400209: '이 광고에 필요한 거래 수수료는 약 {rate}% {currency}이며 현재 사용 가능한 {available} {currency}입니다.',

    400210: '지불 방법',
    400211: '최대 3개 선택',
    400212: '아직 결제 수단이 없습니다.',
    400213: '지불 제한 시간을 선택하십시오',
    400214: '내용을 입력해주세요',
    400215: '트레이딩 유저 조건',
    400216: '거래 사용자 한도를 늘리면 광고가 게재될 가능성이 줄어듭니다',
    400217: '이미 등록',
    400218: '일 후',
    400219: '즉시 해제',

    400220: '나중에 게시',
    400221: '리스크 주의',
    400222: '숫자를 입력해야 합니다',
    400223: '결제수단을 선택해주세요',
    400224: '부동 비율을 입력하세요.',
    400225: '고정 가격을 입력하세요',
    400226: '가격이 시장{num}%에서 벗어났고 계속 게시하는 데 위험이 있습니다.광고를 계속 게시할지 여부를 확인하십시오?',
    400227: '최대 단일 거래 한도는 최소 단일 거래 한도보다 커야 합니다.',
    400228: '최대 단일 거래 한도는 {num} {fiat} 보다 높지 않아야 합니다',
    400229: '최소 단일 거래 한도는 {num} {fiat} 보다 낮지 않아야 합니다',

    400230: '최소 단일 거래 한도는 최대 단일 거래 한도보다 작아야 합니다.',
    400231: '최대 거래 수량은 {num} {currency}보다 높지 않아야 합니다',
    400232: '성공적으로 게시됨',
    400233: 'P2P 거래는 지갑 인증 사용자에게 일시적으로 공개되지 않습니다. P2P 거래를 수행하려면 SuperEx 계정으로 로그인하십시오.',
    400234: 'DAO(다오) 네트워크',
    400235: 'Roadmap',
    400236: '친구 초대',
    400237: '고객센터',
    400238: '에어드롭 존',
    400239: '메인넷 브라우저',

    400240: '테스트넷 브라우저',
    400241: '개발자 문서',
    400242: '이벤트 센터',
    400243: "SuperEx 웹사이트를 사용하고/또는 계정을 유지함으로써, 귀하는 우리의 개정된 <a style=\"color: inherit;text-decoration: underline;\" target=\"_blank\" href=\"https://support.superex.com/hc/ko/articles/11478725012761\">이용 약관</a>,<a style=\"color: inherit;text-decoration: underline;\" target=\"_blank\" href=\"https://support.superex.com/hc/ko/articles/11478678984985\"> 개인정보 처리 방침 </a>및 <a style=\"color: inherit;text-decoration: underline;\" target=\"_blank\" href=\"https://support.superex.com/hc/ko/articles/20761670684057\">쿠키 정책</a>에 동의하는 것입니다. \"X\"를 클릭함으로써, 귀하는 상기한 약관과 정책을 읽고 동의했음을 인정합니다.",
    400244: '테더 구매 | USDT 받기 | 슈퍼엑스 P2P',
    400245: '미션 센터',
    400246: '통화',
    400247: '받다',
    400248: '지출',
    400249: '서용가능한 잔고',

    400250: '지불',
    400251: '주문 내역',
    400252: '금액(거래 수수료 포함)',
    400253: '도착 시간',
    400254: '약 {time}분',
    400255: '힌트',
    400256: '1.您将离开SUPEREX并进入第三方AlchemyPay网页以完成您的订单。',
    400257: '2. 订单完成后，您可以前往您的 <span class="pointer">财务记录</span> 查看详细信息。',
    400258: '3. 如果您需要付款方面的帮助，请联系付款提供商的 <span class="pointer">客户支持</span>。',
    400259: '4. 更多详情请参阅常见问题解答。 <span class="pointer">常问问题</span>',

    400260: '면책 조항을 읽었으며 이에 동의합니다',
    400261: 'SuperEx 에서 암호화폐를 구매하는 방법은 무엇입니까?',
    400262: 'SuperEx 에서 암호화폐를 판매하는 방법?',
    400263: '암호화폐 주문하기',
    400264: '지불할 화폐 통화와 구매하려는 암호화폐를 선택하십시오. 지출하려는 금액을 입력합니다.',
    400265: '신원 확인',
    400266: 'SuperEx 및 결제 제공업체에서 KYC 인증을 완료하여 결제를 완료하세요.',
    400267: '주문 내역 확인',
    400268: '주문 상태를 확인하려면 입금 내역을 확인하세요.。',
    400269: '1. 암호화폐 구매 시 어떤 결제 수단을 사용할 수 있나요?',

    400270: 'SuperEx 는 현재 VISA, Mastercard, Apple Pay, Google Pay 및 기타 결제 방법을 지원합니다. 지원되는 타사 서비스 제공업체에는 Mercuryo, alchemypay가 있습니다.',
    400271: '2. 어떤 암호화폐를 살 수 있나요?',
    400272: 'SuperEx는 BTC, ETH, USDT, LTC, USDC, XRP, BCH, CRV, TRX 및 기타 주류 암호화폐를 지원합니다.',
    400273: '3. SuperEx 는 지원되는 타사 서비스 제공업체의 품질과 가격을 모니터링합니까?',
    400274: 'SuperEx 는 사용자에게 프로세스 중심의 서비스를 제공하기 위해 신뢰할 수 있는 업계 명성, 인기 및 보안을 갖춘 서비스 제공자 그룹을 신중하게 선택했습니다.',
    400275: '4. 암호화폐 구매 수수료는 얼마인가요?',
    400276: '타사 서비스 제공자에게 수수료 및 온체인 전송을 지불합니다. SuperEx 는 수수료를 부과하지 않습니다.',
    400277: '5. 결제 후 암호화폐를 받기까지 얼마나 걸리나요?',
    400278: '제3자 서비스 제공업체 플랫폼에서 결제를 완료하면 약 2~10분 내에 암호화폐가 SuperEx 현물 계정에 입금됩니다.',
    400279: '6. 구매 과정에서 문제가 발생하면 어떻게 해야 하나요?',

    400280: '거래 중 문제가 발생하면 고객 지원팀에 문의하십시오. 결제 완료 후에도 암호화폐를 받지 못한 경우, 제3자 서비스 제공업체에 문의하여 주문 내역을 확인하시기 바랍니다(일반적으로 이것이 가장 효율적인 방법입니다). 현재 지역의 IP 또는 일부 정책상의 이유로 수동 확인을 선택해야 합니다.',
    400281: '단일 거래 한도:',
    400282: '수량을 입력해주세요',
    400283: '결제 채널',
    400284: '가져 오기',
    400285: '잔고가 부족합니다',
    400286: "SuperEx 를 떠나 제3자로서 은행 카드 결제 기능을 제공하는 허가된 금융 기관인 <span>{name}</span>로 이동합니다. 사용 중 발생하는 모든 위험 사건은 <span>{name}</span>이 부담합니다. <span>{name}</span> 서비스를 이용하기 전, <a class='pointer' target='_blank' href={server}>서비스 이용약관</a>을 읽고 동의해 주시기 바랍니다.신용카드 입금과 관련하여 문의사항이 있으신 경우 <a class='pointer' target='_blank' href={Customer}>{name} 고객센터</a>로 문의하여 자세한 내용을 문의하시기 바랍니다.",
    400287: '부인 성명',
    400289: '현물 계좌 사용 가능',
    400290: '확인하다',
    400291: 'ET 존',
    400288: '재원',

    400292:'재무 회계',

    'target_1': '현물 계좌',
    'target_2': 'P2P 계정',
    'target_3': '币本位合约账户',
    'target_4': 'USDT 선물 계좌',
    'target_5': '超级合约账户',
    'target_6': '금융 - 사용 가능한 이자',


    400293: 'https://support.superex.com/hc/en-001/articles/23084120565017',
    400294: '코인을 빨리 구매하세요',

    400295:'Applepay를 사용하려면 사파리 브라우저를 열어주세요',


    410000:'SCS 공중 투하',
    410001:'대리인',

    410002:'금융 업무',
    100756: '재테크',
    100219: '활동',
    101154: '구독 내역',



    122000:"글로벌 DAO 네트워크 및 소셜 미디어 링크",
    122001:"친구 초대로 보상 받기",
    122002:"SuperEx의 개발 계획",
    122003:"사용 중에 마주친 일반적인 문제 해결",
    122004:"임무 완수하고 보상 받기",
    122005:"ET 소개",
    122006:"SuperEx 소개",
    122007:"대리점 추천 리베이트",
    122008:"저렴한 수수료로 암호화폐 구매",
    122009:"편리한 암호화폐 구매",
    122010:"암호화폐 거래 시장",
    122011:"프리 마켓과 메인 마켓에서의 토큰 거래",
    122012:"선물 거래 시장",
    122013:"고가치 프로젝트를 위한 IEO 플랫폼",
    122014:"저축 및 자산 관리로 수익 창출",
    122015:"초저가 쇼핑",
    122016:"흥미진진한 활동의 중심지",
    122017:"BTC 생태계에서 새로운 코인으로 선점",
    122018:"SCS 에어드롭 임무 센터",
    122019:"SCS 체인 공식 웹사이트",
    122020:"SCS 체인 브라우저",
    122021:"SCS 체인 테스트넷 브라우저",
    122022:"개발자 문서",
    122023:"최신 뉴스 탐색",
    122024:"최신 이벤트에 대한 빠른 개요 얻기",
    122025:"유튜브에서 SuperEx 확인하기",
    122026:"블록체인에 대해 모두 배우기",
    122027:"친구와 함께 박스 오픈",
    122028: '상자 보상',


    //合约积分
    9100007: '거래 포인트',
    9100031: '거래 포인트 이벤트 센터',
    
    9100032: '퀀트 펀드',
    9100033: '전문적인 자산관리',

    8100000: '사용자 피드백',
    8000153: '자유 코인 상장',
    8000154: 'BTC 프로토콜 시장',
    6000072: '현물 자유구 주문서',
    6000073: '현물 자유 지역 계정',

    ...ac_kr,
    ...krLocal
}
export default kr;
